import { SidebarToolbox } from './toolbox/sidebarToolbox';
import { Toolbox } from './toolbox/index';
import { sidebarCollapse } from './toolbox/sidebarCollapse';
import userStateManager from '../helpers/stateManager';

export const component = () => {
    // Launcher for all toolbox functionality
    // Dev note on not using React:
    // - Wanted to avoid potential complications with Reacts virtual DOM and drag and drop. Toolbox is still a class
    // - Avoided a React drag and drop plugin due to keyboard requirements and grid view (some plugins only support list view)

    sidebarCollapse();
    mobileInfoCallouts();

    //Show 1s spinner for the first load
    const minSpinnerTime = sessionStorage.getItem('userState') === 'undefined' || sessionStorage.getItem('userState') === null ? 1000 : 0;

    userStateManager.getUserState(function (userData) {
        userStateManager.getAllToolboxItems(function (toolboxResponse) {
            //console.log(userData);
            //console.log(toolboxResponse);

            //Init each toolbox

            setTimeout(() => {
                $('[data-toolbox]').each(function () {
                    const strToolboxSavedKey = $(this).data('toolbox'); //EG 'MySupport', matches API
                    const strToolboxAllKey = $(this).data('toolbox') + 'Section'; //EG 'MySupportSection', ensure this data attribute matches API data

                    if (toolboxResponse.data && userData && strToolboxAllKey in userData && strToolboxSavedKey in toolboxResponse.data) {
                        new Toolbox(
                            userData[strToolboxAllKey],
                            toolboxResponse.data[strToolboxSavedKey],
                            strToolboxAllKey,
                            userData.ShowTooltips,
                            $(this)
                        );
                    } else {
                        console.warn('A toolbox data attribute was found but there was no matching data, or the API returned no data.');
                    }
                });

                //Init sidebar toolboxes
                $('[data-sidebar]').each(function () {
                    const strToolboxSavedKey = $(this).data('sidebar'); //EG 'MySupport', matches API
                    const strToolboxAllKey = $(this).data('sidebar') + 'Section'; //EG 'MySupportSection', ensure this data attribute matches API data
                    if (userData && strToolboxAllKey in userData && strToolboxSavedKey in toolboxResponse.data) {
                        new SidebarToolbox(userData[strToolboxAllKey], toolboxResponse.data[strToolboxSavedKey], strToolboxAllKey, $(this));
                    } else {
                        console.warn('A sidebar toolbox data attribute was found but there was no matching data.');
                    }
                });
            }, minSpinnerTime);
        });
    });
};

const mobileInfoCallouts = () => {
    $(document).on('click', '.popover .btn-close', function () {
        $(this).parents('.popover').popover('hide');
    });

    const getPopoverHtml = (tooltip) => {
        return `
                <button class="btn-close">
                    <span class="sr-only">Close</span>
                </button>
                <div class="popover__text">
                    ${tooltip}
                </di>
            `;
    };

    $('.js-callout-btn')
        .each(function () {
            const $this = $(this);
            const calloutContent = $this.parents('.toolbox-panel').find('.js-callout-content').html();

            const html = getPopoverHtml(calloutContent);

            $this.popover({
                trigger: 'manual',
                placement: 'bottom',
                content: html,
                html: true,
            });
        })
        .on('click', function (e) {
            $(this).popover('toggle');
        });
};
